<template>
<div>
    <template>
        <!-- [+] Section title. -->
        <q-item class="min-height-0 p-0">
            <q-item-section side class="text-dark">
                <strong>{{ translate('include_exercises') }}</strong>
            </q-item-section>
            <q-item-section></q-item-section>
            <q-item-section side>
                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddExercisesPanel" dense round color="primary" no-caps icon="add">
                    <q-tooltip :offset="[10, 10]">{{ add_exercises_title }}</q-tooltip>
                </q-btn>
            </q-item-section>
        </q-item>
        <!-- [-] Section title. -->
        
        <!-- [+] Write in one line. -->

        <!-- Selected items to save. -->
        <template v-if="selected_items && selected_items.length">
            <div class="inline_form_items align-items-center small" v-for="(item,i) in selected_items" :key="'selected-item-' + i + '-' + item.id">
                <q-input class="mb-0" square color="primary" :style="!$q.screen.lt.sm ? desktop_width: mobile_width" style="margin-left: -0.5rem; display: inline;" dense type="text" :val="item.reps" v-model="item.reps" :id="'reps' + item.id" />
                <span class="inline-block align-self-end mb-3">x</span>
                <q-select @input="setSelectedExercise(item)" color="primary" :id="'exerciseItem' + item.id" class="mb-0 custom-dense-select" dense :options="default_item.options" v-model="item.name" map-options filter />
                <template>
                    <span class="inline-block align-self-end mb-3">@</span>
                    <q-input class="mb-0" square color="primary" :style="!$q.screen.lt.sm ? desktop_width: mobile_width" style="display: inline;" dense :id="'exercise_unit' + item.id" type="text" v-model="item.value_per_rep" :val="item.value_per_rep" />
                    <span class="inline-block align-self-end mb-3 =" style="min-width: 22px;">{{ item && item.unit_name ? item.unit_name : '' }}</span>
                </template>
                <q-btn dense round flat icon="delete" color="danger" @click.stop="deleteFromSelected(item.value ? item.value : item.id)" />
            </div>
        </template>

        <!-- Default item to add. -->
        <div class="inline_form_items position-relative align-items-center">
            <q-input autocomplete="off" square :color="default_item_reps_msg !== '' ? 'danger' : 'primary'" :style="!$q.screen.lt.sm ? desktop_width: mobile_width" style="margin-left: -0.5rem; display: inline;" dense ref="default_reps_input" type="text" @keyup.prevent="focusExercises" :val="default_item.reps" v-model="default_item.reps" id="reps" />
            <span class="inline-block align-self-end mb-3">x</span>
            <div class="autocomplete" id="autocomplete-wrapper">
                <q-input autocomplete="off" square :color="selected_exercise_msg !== '' ? 'danger' : 'primary'" ref="exercises_select" dense type="text" @input="onChange" v-model="search" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter.prevent="onEnter" />
                <div v-if="isOpen" class="actions">
                    <q-btn icon="fas fa-chevron-up" size="0.65rem" color="grey" dense flat @click.stop="closeAutocompleteResults"></q-btn>
                </div>
                <ul ref="results" id="autocomplete-results" v-if="isOpen" class="autocomplete-results">
                    <li class="loading" v-if="isLoading">
                        {{ translate('loading') }}
                    </li>
                    <li v-else v-for="(result, i) in results" :key="i" @input="setResult" @keyup="setResult" @click="setResult(result)" class="autocomplete-result" :class="{ 'is-active': i === arrowCounter }">
                        {{ result }}
                    </li>
                </ul>
            </div>
            <span class="inline-block align-self-end mb-3">@</span>
            <q-input autocomplete="off" square color="primary" :style="!$q.screen.lt.sm ? desktop_width: mobile_width" style="display: inline;" dense @keyup.enter.stop="sendToSelectedItems" ref="exercise_value_per_rep" id="exercise_unit" type="text" v-model="default_item.value_per_rep" :val="default_item.value_per_rep" />
            <span class="inline-block align-self-end mb-3">{{ selected_exercise && selected_exercise.unit_name ? selected_exercise.unit_name : '' }}</span>
            <q-btn dense flat round icon="check" color="positive" @click.stop="sendToSelectedItems" />
        </div>
        <!-- [-] Write in one line. -->
        <infinite-loading @infinite="getExercises" ref="infiniteLoading" :identifier="'included-exercises-' + entity_type + '-new-' +infExercises" slot="append" />
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"

import InfiniteLoading from 'vue-infinite-loading'
import AddItem from './AddItem'

export default {
    name: 'IncludeExercisesLight',
    props: ['get_route', 'placeholder_find_item', 'entity_type'],
    watch: {
        items: function (val, oldValue) {
            // actually compare them
            if (val.length !== oldValue.length) {
                this.results = val
                this.isLoading = false
            }
        },
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    components: {
        'infinite-loading': InfiniteLoading
    },
    data: function () {
        return {
            isOpen: false,
            results: [],
            search: '',
            isLoading: false,
            arrowCounter: 0,

            default_item_reps_msg: '',
            selected_exercise_msg: '',

            selected_items: [],
            selected_exercise: null,
            default_item: {
                reps: '',
                name: '',
                value_per_rep: '',
                unit_name: '',
                exercise_id: '',
                options: [{
                        label: 'exercise 1', // name
                        value: 1, // id
                        unit_name: 'cm' // unit_name
                    },
                    {
                        label: 'exercise 2', // name
                        value: 2, // id
                        unit_name: 'kg' // unit_name
                    }
                ],
            },
            exercise_reps: null,
            exercise_value_per_rep: null,
            exercises: [],
            page: 1,
            state: {},
            infExercises: +new Date(),
            isTypingItem: false,
            searchItem: '',
            msg: '',
            item: {
                note: null,
                reps: 0,
                value_per_rep: 0
            }
        }
    },
    computed: {
        include_single_choice: function () {
            return [{
                field: this.field_training_category,
                get_route_to_include: this.get_route_training_categories
            }]
        },
        field_training_category: function () {
            return 'training_category'
        },
        get_route_training_categories: function () {
            return baseUrl + 'training_categories'
        },
        desktop_width: function () {
            return 'min-width: 80px; width: 80px; max-width: 100%;'
        },
        mobile_width: function () {
            return 'min-width: 40px; width: 40px; max-width: 100%;'
        },
        stringOptions: {
            get() {
                return this.default_item.options.map(elem => {
                    return elem.label
                })
            },
            set() {
                return this.default_item.options.map(elem => {
                    return elem.label
                })
            }
        },
        add_exercises_title: function () {
            return this.translate('new_exercise')
        }
    },
    mounted: function () {
        eventBus.$on('update_all', () => {
            this.resetMainList()
        })
        eventBus.$on('update_selected_exercise', (result) => {
            this.updateSelectedExercise(result)
        })
    },
    methods: {
        updateSelectedExercise: _.debounce(function (result) {
            if (result && result.name) {
                this.resetMainList()
                this.setResult(result.name)
                this.onArrowDown()
                this.onArrowUp()
                let result_elem = this.default_item.options.filter(elem => elem.label.toLowerCase().indexOf(result.name.toLowerCase()) > -1)[0]
                if (result_elem) {
                    this.setSelectedExercise(result_elem)
                }
                this.sendToSelectedItems()
            }
        }, 0),
        setSelectedExercise: _.debounce(function (item) {
            let exercise = this.default_item.options.filter(elem => elem.value === item.exercise_id) ? this.default_item.options.filter(elem => elem.value === item.exercise_id)[0] : null
            var objIndex = this.selected_items.findIndex((obj => obj.exercise_id === item.exercise_id))
            if (exercise) {
                this.selected_items[objIndex]['unit_name'] = exercise && exercise.unit_name ? exercise.unit_name : ''
            }
            eventBus.$emit('update_selected_exercises', this.selected_items)
        }, 300),
        deleteFromSelected: _.debounce(function (id) {
            let objId = this.selected_items.findIndex((obj => obj.id === id))
            this.selected_items.splice(objId, 1)
            let selected_items_set = this.selected_items
            eventBus.$emit('update_selected_exercises', selected_items_set)
        }, 200),
        onChange() {
            // Let's w`arn the parent that a change was made
            this.$emit('input', this);

            // Is the data given by an outside ajax request?
            if (this.isAsync) {
                this.isLoading = true;
            } else {
                // Let's  our flat array
                this.filterResults();
                this.isOpen = true;
            }
        },

        filterResults() {
            // first uncapitalize all the things
            this.results = this.stringOptions.filter((item) => {
                return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
            })
            this.onArrowDown()
            this.onArrowUp()
        },
        scrollItemIntoView: _.debounce(function() {
            let results_refs = this.$refs.results
            if (this.results[this.arrowCounter] && results_refs.querySelector('.autocomplete-result.is-active')) {
                results_refs.querySelector('.autocomplete-result.is-active').scrollIntoView({behavior: 'smooth'})
            }
        }, 200),
        setResult: _.debounce(function (result) {
            this.search = result
            this.isOpen = false
            if (result) {
                this.selected_exercise = this.default_item.options.filter(elem => elem.label.toLowerCase() === result.toLowerCase()) 
                    ? this.default_item.options.filter(elem => elem.label.toLowerCase() === result.toLowerCase())[0] 
                    : null
            }
        }, 200),
        onArrowDown(evt) {
            if (this.arrowCounter < this.results.length - 1) {
                this.arrowCounter = this.arrowCounter + 1
                this.scrollItemIntoView()
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1
                this.scrollItemIntoView()
            }
        },
        onEnter: _.debounce(function () {
            let arrowCounter = this.arrowCounter
            let result = this.results[arrowCounter]
            if (result) {
                this.search = result
                this.selected_exercise = this.default_item.options.filter(elem => elem.label.toLowerCase() === result.toLowerCase()) 
                    ? this.default_item.options.filter(elem => elem.label.toLowerCase() === result.toLowerCase())[0] 
                    : null;
                this.isOpen = false
                this.arrowCounter = -1

                this.focusUnitInput()
            }
        }, 100),
        closeAutocompleteResults: function () {
            this.isOpen = false
            this.arrowCounter = -1
        }, 
        filterExercises: function (val, update, abort) {
            update(() => {
                const needle = val.toLowerCase()
                this.default_item.options = this.stringOptions.filter(v => v.toLowerCase().indexOf(needle) > -1)
            })
        },
        focusExercises: _.debounce(function () {
            if (this.default_item.reps) {
                let obj = this.default_item.reps.split(' ')
                // let obj = document.getElementById('reps')
                if (obj && obj.includes('x') === true) {
                    this.focusExercisesSelect()
                }
            }
        }, 0),
        focusUnit: _.debounce(function (value) {
            this.focusUnitInput()
        }, 0),
        focusUnitInput: _.debounce(function () {
            this.$refs.exercise_value_per_rep.focus()
        }, 100),
        focusExercisesSelect: _.debounce(function () {
            // remove the x string from reps.
            this.$refs.exercises_select.focus()
            this.default_item.reps = this.default_item.reps.replace(' x', '')
        }, 100),
        sendToSelectedItems: _.debounce(function () {
            // A little validation for empty reps or exercise.
            if (!this.default_item.reps) {
                this.default_item_reps_msg = this.translate('enter_reps')
                if (this.$refs && this.$refs.default_reps_input) {
                    this.$refs.default_reps_input.focus()
                }
                return false
            }
            if (!this.selected_exercise) {
                if (this.$refs && this.$refs.exercises_select) {
                    this.selected_exercise_msg = this.translate('select_exercise')
                    this.$refs.exercises_select.focus()
                }
                return false
            }
            this.default_item_reps_msg = ''
            this.selected_exercise_msg = ''

            // Populate the selected items.
            this.selected_items.push({
                reps: this.default_item.reps ? this.default_item.reps : null,
                value_per_rep: this.default_item.value_per_rep ? this.default_item.value_per_rep : null,
                unit_name: this.selected_exercise && this.selected_exercise.unit_name ? this.selected_exercise.unit_name : null,
                exercise: this.selected_exercise,
                exercise_id: this.selected_exercise.value,
                name: this.selected_exercise.label,
            })

            // Emit the set with items.
            let selected_items_set = this.selected_items
            eventBus.$emit('update_selected_exercises', selected_items_set)

            // Reset fields.
            this.resetFields()

            return false
        }, 300),
        resetFields: _.debounce(function () {
            this.default_item.reps = null
            this.default_item.value_per_rep = null
            this.selected_exercise = null
            if (this.$refs && this.$refs.default_reps_input) {
                this.$refs.default_reps_input.focus()
                this.default_item_reps_msg = ''
            }
            this.search = null
        }, 100),
        showAddExercisesPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddItem,
                props: {
                    add_item_title: this.add_exercises_title,
                    post_route: baseUrl + 'exercises',
                    entity_type: this.entity_type,
                    include_single_choice: this.include_single_choice,
                    field_unit: true,
                    field_unit_title: 'unit',
                    field_muscle: true,
                    field_muscle_title: 'muscles',
                    field_equipment: true,
                    field_equipment_title: 'equipment',
                    field_medical_problem: true,
                    field_medical_problem_title: 'medical_problems',
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_selected_exercise', result)
                }
            })
        },
        resetMainList: function () {
            this.page = 1
            this.exercises = []
            this.infExercises++
        },
        getExercises: function ($state) {
            var route = baseUrl + 'exercises/extended'
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&search=' + this.searchItem
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.exercises.push(...response.data.items)
                        this.default_item.options = this.exercises.map(elem => {
                            let array = elem
                            array = {
                                label: elem.name,
                                value: elem.id,
                                unit_name: elem.unit_name
                            }
                            return array
                        })
                        if (this.searchItem) {
                            this.search = this.default_item.options[0].label
                            this.selected_exercise = this.default_item.options[0]
                        }
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: 50vh;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}

body .search-input {
    width: calc(100% + 30px);
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    transform-origin: center;
    transform: scale(.5);
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.list-group-item i {
    cursor: pointer;
}

.draggable-item:hover {
    border-style: dotted;
    border-color: rgba(0, 0, 0, .85);
}
</style>
