<template>
<div>
    <template>
        <!-- [+] Section title. -->
        <q-item class="min-height-0 p-0">
            <q-item-section side class="text-dark">
                <strong>{{ translate('include_exercises_collections') }}</strong>
            </q-item-section>
            <q-item-section></q-item-section>
            <q-item-section side>
                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddExercisesCollectionsPanel" dense round color="primary" no-caps icon="add">
                    <q-tooltip :offset="[10, 10]">{{ add_exercises_collections_title }}</q-tooltip>
                </q-btn>
            </q-item-section>
        </q-item>
        <!-- [-] Section title. -->
        
        <!-- [+] Write in one line. -->

        <!-- Selected items to save. -->
        <template v-if="selected_items && selected_items.length">
            <div v-for="(item,i) in selected_items" :key="'selected-item-' + i + '-' + item.id">
                <div class="inline_form_items align-items-start small">
                    <div class="d-flex">
                        <label class="m-0 pr-2">
                            <q-btn size="md" @click.stop="item.time=null;item.timing_type='rounds'" icon="fa fa-redo" dense round :color="item.timing_type && item.timing_type === 'rounds' ? 'blue-10' : 'secondary'" />
                        </label>
                        <label class="m-0">
                            <q-btn size="md" @click.stop="item.rounds=null;item.timing_type='time'" icon="fa fa-clock" dense round :color="item.timing_type && item.timing_type === 'time' ? 'blue-10' : 'secondary'" />
                        </label>
                    </div>
                    <div :style="!$q.screen.lt.sm ? desktop_width: mobile_width">
                        <q-input class="m-0 p-0" v-if="item.timing_type && item.timing_type === 'rounds'" :color="!item.rounds ? 'danger' : 'primary'" dense square v-model="item.rounds" />
                        <q-input v-if="item.timing_type && item.timing_type === 'time'" :color="!item.time_original ? 'danger' : 'primary'" dense sqaure no-error-icon v-model="item.time_original" mask="fulltime">
                            <template v-if="item.timing_type && item.timing_type === 'time'" v-slot:append>
                                <q-icon name="access_time" class="cursor-pointer">
                                    <q-popup-proxy transition-show="scale" transition-hide="scale">
                                    <q-time v-model="item.time_original" with-seconds format24h>
                                        <div class="row items-center justify-center full-width">
                                            <q-btn v-close-popup :label="translate('close')" class="full-width" color="primary" dense flat no-caps />
                                        </div>
                                    </q-time>
                                    </q-popup-proxy>
                                </q-icon>
                            </template>
                        </q-input>
                    </div>
                    <span class="inline-block align-self-start mt-3" v-if="item.rounds">x</span>
                    <span class="inline-block align-self-start mt-3" v-if="item.time_original"><i class="fas fa-clock text-grey cursor-default"></i></span>
                    <div class="col">
                        <q-select class="select-label-custom-padding" :label="item.collection.training_phase_name" @input="setSelectedCollection" :class="item.collection.exercises && item.collection.exercises.length ? 'mb-0' : ''" color="primary" :id="'exercisesCollectionItem' + item.collection_id" dense :options="default_item.options" v-model="item.collection" map-options filter />
                        <div class="full-width pl-2 pr-2 pt-1" v-if="item.collection.exercises && item.collection.exercises.length && !$q.screen.lt.sm">
                            <div v-for="(exercise,i) in item.collection.exercises" :key="'collection-exercises-list-item' + i + '-' + exercise.id">
                                <!-- reps -->
                                <strong>{{ exercise.reps }} <span>x</span></strong>&nbsp;
                                <!-- exercise_name -->
                                <strong class="text-blue-10">{{ exercise.name }}</strong>&nbsp;
                                <!-- value unit -->
                                <template v-if="exercise.unit_name">
                                    @
                                    <strong>{{ exercise.value_per_rep }} {{ exercise.unit_name }}</strong>
                                </template>
                            </div>
                        </div>
                    </div>
                    <q-btn v-if="item &&item.collection_id" dense round flat icon="delete" color="danger" @click.stop="deleteFromSelected(item.collection_id)" />
                </div>
                <div class="col p-2 small align-self-start" v-if="$q.screen.lt.sm">
                    <div class="full-width" v-if="item.collection.exercises && item.collection.exercises.length">
                        <div v-for="(exercise,i) in item.collection.exercises" :key="'collection-exercises-list-item' + i + '-' + exercise.id">
                            <!-- reps -->
                            <strong>{{ exercise.reps }} <span>x</span></strong>&nbsp;
                            <!-- exercise_name -->
                            <strong class="text-blue-10">{{ exercise.name }}</strong>&nbsp;
                            <!-- value unit -->
                            <template v-if="exercise.unit_name">
                                @
                                <strong>{{ exercise.value_per_rep }} {{ exercise.unit_name }}</strong>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <!-- Default item to add. -->
        <div class="inline_form_items position-relative align-items-start">
            <div class="d-flex">
                <!--  :label="translate('rounds')" -->
                <!--  :label="translate('time')" -->
                <label class="m-0 pr-2">
                    <q-btn size="md" @click.stop="default_item.time=null;default_item.timing_type='rounds'" icon="fa fa-redo" dense round :color="default_item.timing_type && default_item.timing_type === 'rounds' ? 'blue-10' : 'secondary'" />
                </label>
                <label class="m-0">
                    <q-btn size="md" @click.stop="default_item.rounds=null;default_item.timing_type='time'" icon="fa fa-clock" dense round :color="default_item.timing_type && default_item.timing_type === 'time' ? 'blue-10' : 'secondary'" />
                </label>
            </div>
            <div :style="!$q.screen.lt.sm ? desktop_width: mobile_width" style="display: inline;">
                <q-input v-if="default_item.timing_type && default_item.timing_type === 'rounds'" @input="focusCollections(default_item.rounds)" :color="default_item_rounds_msg ? 'danger' : 'primary'" ref="default_rounds_input" dense square v-model="default_item.rounds"></q-input>
                
                <q-input v-if="default_item.timing_type && default_item.timing_type === 'time'" :color="default_item_time_msg !== '' ? 'danger' : 'primary'" ref="default_time_input" dense sqaure no-error-icon v-model="default_item.time" mask="fulltime">
                    <template v-if="default_item.timing_type && default_item.timing_type === 'time'" v-slot:append>
                        <q-icon name="access_time" class="cursor-pointer">
                            <q-popup-proxy transition-show="scale" transition-hide="scale">
                            <q-time v-model="default_item.time" with-seconds format24h>
                                <div class="row items-center justify-center full-width">
                                    <q-btn @click.stop="focusCollectionsSelect()" v-close-popup :label="translate('close')" class="full-width" color="primary" dense flat no-caps />
                                </div>
                            </q-time>
                            </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
            </div>
            <span class="inline-block align-self-start mt-3" v-if="default_item.timing_type === 'rounds'">x</span>
            <span class="inline-block align-self-start mt-3" v-if="default_item.timing_type === 'time'"><i class="fas fa-clock text-grey cursor-default"></i></span>
            <div class="autocomplete" id="autocomplete-wrapper">
                <div class="position-absolute top pl-2" style="margin-top: -5px;" v-if="!isOpen && (selected_collection && selected_collection.training_phase_name)"><small class="text-muted">{{ selected_collection.training_phase_name }}</small><br /></div>
                <q-input autocomplete="off" square :color="selected_collection_msg !== '' ? 'danger' : 'primary'" ref="collections_select" dense type="text" @input="onCollectionChange" v-model="search" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter.prevent="onEnter" />
                <div v-if="isOpen" class="actions">
                    <q-btn icon="fas fa-chevron-up" size="0.65rem" color="grey" dense flat @click.stop="closeAutocompleteResults"></q-btn>
                </div>
                <ul ref="results" id="autocomplete-results" v-if="isOpen" class="autocomplete-results">
                    <li class="loading" v-if="isLoading">
                        {{ translate('loading') }}
                    </li>
                    <li v-else v-for="(result, i) in results" :key="i" @input="setResult" @keyup="setResult" @click="setResult(result)" class="autocomplete-result" :class="{ 'is-active': i === arrowCounter }">                        
                        <template v-if="$q.screen.lt.sm && result.training_phase_name"><small class="text-muted">{{ result.training_phase_name }}</small><br /></template>
                        <template v-if="!$q.screen.lt.sm && result.training_phase_name"><small class="text-muted mr-1">{{ result.training_phase_name }}</small></template>{{ result.name }}
                        <q-btn class="mb-0 pull-right" @click.stop.prevent="result.exercises && result.exercises.length ? result.exercises = [] : getExercisesForSelectedCollection(result.value)" dense flat round :color="result.exercises && result.exercises.length ? 'blue-10' : 'secondary'" icon="fas fa-dumbbell" size="0.5rem" />
                        <div class="full-width pl-2 pr-2 result-exercises" v-if="result.exercises">
                            <div v-for="(exercise,i) in result.exercises" :key="'result-exercises-list-item' + i + '-' + exercise.id">
                                <!-- reps -->
                                <strong>{{ exercise.reps }} <span>x</span></strong>&nbsp;
                                <!-- exercise_name -->
                                <strong class="text-blue-10">{{ exercise.name }}</strong>&nbsp;
                                <!-- value unit -->
                                <template v-if="exercise.unit_name">
                                @
                                    <strong>{{ exercise.value_per_rep }} {{ exercise.unit_name }}</strong>
                                </template>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="full-width pl-2 pr-2 selected-result-exercises" v-if="selected_collection && selected_collection.exercises">
                    <q-btn class="mb-0 pull-right" @click.stop.prevent="selected_collection.exercises && selected_collection.exercises.length ? selected_collection.exercises = [] : getExercisesForSelectedCollection(selected_collection.value)" dense flat round :color="selected_collection.exercises && selected_collection.exercises.length ? 'blue-10' : 'secondary'" icon="fas fa-dumbbell" size="0.5rem" />
                    <div :class="i === 0 ? 'pr-4' : ''" v-for="(exercise,i) in selected_collection.exercises" :key="'selected-result-exercises-list-item' + i + '-' + exercise.id">
                        <!-- reps -->
                        <strong>{{ exercise.reps }} <span>x</span></strong>&nbsp;
                        <!-- exercise_name -->
                        <strong class="text-blue-10">{{ exercise.name }}</strong>&nbsp;
                        <!-- value unit -->
                        <template v-if="exercise.unit_name">
                        @
                            <strong>{{ exercise.value_per_rep }} {{ exercise.unit_name }}</strong>
                        </template>
                    </div>
                </div>
            </div>
            <q-btn dense flat round icon="check" color="positive" @click.stop="sendToSelectedItems" />
        </div>
        <!-- [-] Write in one line. -->
        <infinite-loading @infinite="getCollections" ref="infiniteLoading" :identifier="'included-collections-' + entity_type + '-new-' +infCollections" slot="append" />
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"

import InfiniteLoading from 'vue-infinite-loading'
import AddItem from './AddItem'

export default {
    name: 'IncludeExercisesCollectionsLight',
    props: ['get_route', 'placeholder_find_item', 'entity_type'],
    components: {
        'infinite-loading': InfiniteLoading
    },
    mounted: function () {
        eventBus.$on('update_selected_collection', (result) => {
            this.updateSelectedCollection(result)
        })
    },
    data: function () {
        return {
            selected_exercises_for_collection: null,
            isOpen: false,
            results: [],
            search: '',
            isLoading: false,
            arrowCounter: 0,

            default_item_rounds_msg: '',
            default_item_time_msg: '',
            selected_collection_msg: '',

            selected_items: [],
            selected_collection: null,
            // selected_collection: {
            //     exercises: [],
            //     training_phase_name: '',
            // },
            default_item: {
                rounds: '',
                time: '',
                timing_type: 'rounds',
                name: '',
                training_phase_name: '',
                exercise_id: '',
                options: [{
                        label: 'exercises collection 1', // name
                        value: 1, // id,
                        exercises: []
                    },
                    {
                        label: 'exercises collection 2', // name
                        value: 2, // id
                        exercises: []
                    }
                ],
            },
            collections: [],
            page: 1,
            state: {},
            infCollections: +new Date(),
            isTypingItem: false,
            searchItem: '',
            msg: '',
            item: {
                note: null,
                reps: 0,
            }
        }
    },
    computed: {
        include_single_choice: function () {
            return [{
                field: this.field_training_phase,
                get_route_to_include: this.get_route_training_phases
            }]
        },
        field_training_phase: function () {
            return 'training_phase'
        },
        get_route_training_phases: function () {
            return baseUrl + 'training_phases'
        },
        desktop_width: function () {
            return 'min-width: 100px; width: 100px; max-width: 100%;'
        },
        mobile_width: function () {
            return 'min-width: 60px; width: 60px; max-width: 100%;'
        },
        stringOptions: {
            get() {
                return this.default_item.options.map(elem => {
                    return elem.label
                })
            },
            set() {
                return this.default_item.options.map(elem => {
                    return elem.label
                })
            }
        },
        add_exercises_collections_title: function () {
            return this.translate('new_exercises_collection')
        }
    },
    methods: {
        updateSelectedCollection: _.debounce(function (result) {
            if (result && result.name) {
                var url = baseUrl + 'exercises_collections?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&search=' + result.name
                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken(),
                }
                axios.get(url, {
                        headers: headers
                    })
                    .then(response => {
                        if (response.data.items && response.data.items.length) {
                            this.selected_collection = response.data.items[0]
                            this.selected_collection.value = response.data.items[0].id
                            this.selected_collection.label = response.data.items[0].name
                            
                            if (this.selected_collection) {
                                this.resetCollections()
                                this.setResult(result)
                                this.onArrowDown()
                                this.onArrowUp()
                                this.getExercisesForSelectedCollection(response.data.items[0].id, true)
                            }
                        }
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            }
        }, 0),
        getExercisesForSelectedCollection: function (collection_id, send) {
            let selected_collection = this.selected_collection
            let cid = selected_collection && selected_collection.value ? selected_collection.value : null
            if (collection_id) {
                cid = collection_id
            }
            if (cid) {
                var url = baseUrl + 'exercises_collections/' + cid
                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken(),
                }
                axios.get(url, {
                        headers: headers
                    })
                    .then(response => {
                        if (response.data.exercises && response.data.exercises.length) {
                            if (collection_id) {
                                let objIndex = this.default_item.options.findIndex((obj) => obj.value === cid)
                                if (this.default_item.options[objIndex]) {
                                    this.default_item.options[objIndex]['exercises'] = response.data.exercises
                                    this.default_item.options[objIndex]['training_phase_name'] = response.data.training_phase_name
                                }
                            }
                            if (send === true) {
                                if (selected_collection) {
                                    selected_collection['exercises'] = response.data.exercises
                                    selected_collection['training_phase_name'] = response.data.training_phase_name
                                }
                                this.selected_collection = selected_collection
                                this.sendToSelectedItems()
                                this.selected_collection = null
                            }
                        }
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            }
        },
        resetCollections: function () {
            this.page = 1
            this.default_item.options = []
            this.collections = []
            this.infCollections++
        },
        timeToSeconds: function (time) {
            if (time) {
                var seconds = 0,
                    hoursSeconds = parseInt(time.slice(0,2)) * 60 * 60,
                    minutesSeconds = parseInt(time.slice(3,5)) * 60,
                    secondsSeconds = parseInt(time.slice(6,8))
                    
                seconds = hoursSeconds + minutesSeconds + secondsSeconds
    
                return seconds
            }
            return 0
        },
        setSelectedCollection: _.debounce(function (item) {
            this.getExercisesForSelectedCollection(item.value)
            eventBus.$emit('update_selected_exercises_collections', this.selected_items)
        }, 200),
        deleteFromSelected: _.debounce(function (id) {
            let objId = this.selected_items.findIndex((obj => obj.collection_id === id))
            this.selected_items.splice(objId, 1)
            let selected_items_set = this.selected_items
            eventBus.$emit('update_selected_exercises_collections', selected_items_set)
        }, 200),
        onCollectionChange: _.debounce(function () {
            // Let's w`arn the parent that a change was made
            this.$emit('input', this)

            // Is the data given by an outside ajax request?
            this.filterResults()
            this.toggleIsOpen()
            this.onArrowDown()
            this.onArrowUp()
        }, 200),
        toggleIsOpen: function () {
            if (this.results && this.results.length > 0) {
                this.isOpen = true
            } else {
                this.isOpen = false
            }
        },
        filterResults: function () {
            // first uncapitalize all the things
            this.results = []
            if (this.search && this.default_item && this.default_item.options) {
                this.results = this.default_item.options.filter((item) => {
                    let object = null
                    if (item.label.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || (item.training_phase_name && item.training_phase_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)) {
                        object = {training_phase_name: item.training_phase_name, name: item.label, exercises: []}
                    }
                    if (object !== null) {
                        return object
                    }
                })
            }
        },
        scrollItemIntoView: _.debounce(function() {
            let results_refs = this.$refs.results
            if (this.results[this.arrowCounter] && results_refs && results_refs.querySelector('.autocomplete-result.is-active')) {
                results_refs.querySelector('.autocomplete-result.is-active').scrollIntoView({behavior: 'smooth'})
            }
        }, 200),
        setResult: _.debounce(function (result) {
            if (this.default_item && this.default_item.options) {
                this.search = result.name
                this.isOpen = false
                if (result.name) {
                    this.selected_collection = this.default_item.options.filter(elem => elem.label.toLowerCase() === result.name.toLowerCase()) 
                        ? this.default_item.options.filter(elem => elem.label.toLowerCase() === result.name.toLowerCase())[0] 
                        : null
                }
            }
        }, 200),
        onArrowDown(evt) {
            if (this.arrowCounter < this.results.length - 1) {
                this.arrowCounter = this.arrowCounter + 1
                this.scrollItemIntoView()
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1
                this.scrollItemIntoView()
            }
        },
        onEnter: _.debounce(function () {
            let result = this.results[this.arrowCounter]
            if (result) {
                this.search = result.name
                this.selected_collection = this.default_item.options.filter(elem => elem.label.toLowerCase() === result.name.toLowerCase()) 
                    ? this.default_item.options.filter(elem => elem.label.toLowerCase() === result.name.toLowerCase())[0] 
                    : null
                this.isOpen = false
                this.arrowCounter = -1
                this.sendToSelectedItems()
            }
        }, 200),
        closeAutocompleteResults: function () {
            this.isOpen = false
            this.arrowCounter = -1
        }, 
        focusCollections: _.debounce(function (elem) {
            if (elem) {
                let obj = elem.split(' ')
                if (obj && obj.includes('x') === true) {
                    this.focusCollectionsSelect()
                }
            }
        }, 0),
        focusCollectionsSelect: _.debounce(function () {
            // remove the x string.
            this.$refs.collections_select.focus()
            this.$refs.collections_select.select()
            if (this.default_item && this.default_item.rounds) {
                this.default_item.rounds = this.default_item.rounds.replace(' x', '')
            }
        }, 100),
        sendToSelectedItems: function () {
            let default_item = this.default_item
            if (this.validate(default_item) === true) {
                this.default_item_timing_msg = ''
                this.selected_collection_msg = ''

                // Format time.
                let formatted_time = this.timeToSeconds(this.default_item.time)

                // Populate the selected items.
                //-- Define selected collection.
                let selected_collection = this.selected_collection
                //-- Get the exercises for the selected collection.
                if (selected_collection && selected_collection.value) {
                    this.getExercisesForSelectedCollection(selected_collection.value)
                    //-- Send to selected items, key: value.
                    this.selected_items.push({
                        rounds: this.default_item.rounds ? this.default_item.rounds : null,
                        time: this.default_item.time ? formatted_time : null,
                        time_original: this.default_item.time ? this.default_item.time : null,
                        timing_type: this.default_item.timing_type ? this.default_item.timing_type : null,
                        collection: selected_collection,
                        exercises: selected_collection.exercises ? selected_collection.exercises : [],
                        collection_id: selected_collection.value,
                        name: selected_collection.label,
                        training_phase_name: selected_collection.training_phase_name,
                    })

                    // Emit the set with items.
                    let selected_items_set = this.selected_items
                    if (selected_items_set) {
                        eventBus.$emit('update_selected_exercises_collections', selected_items_set)
                        // Reset fields.
                        this.resetFields()
                    }
                }
            }
        }, 
        validate: function (default_item) {
            // A little validation.
            if (default_item) {
                let valid = true
                if (default_item.rounds === '' && default_item.timing_type === 'rounds') {
                    this.default_item_rounds_msg = this.translate('enter_rounds')
                    if (this.$refs && this.$refs.default_rounds_input) {
                        this.$refs.default_rounds_input.focus()
                    }
                    valid = false
                } else if (!default_item.time && default_item.timing_type === 'time') {
                    this.default_item_time_msg = this.translate('enter_time')
                    if (this.$refs && this.$refs.default_time_input) {
                        this.$refs.default_time_input.focus()
                    }
                    valid = false
                } else if (!this.selected_collection) {
                    this.selected_collection_msg = this.translate('choose_collection')
                    if (this.$refs && this.$refs.collections_select) {
                        this.$refs.collections_select.focus()
                    }
                    valid = false
                }
                if (valid === false) {
                    this.resetCollections()
                }
                return valid
            }
        },
        resetFields: function () {
            let start = true
            let search = ''
            if (start === true) {
                start = false
                this.isOpen = false
                this.selected_collection = null
                let default_item = this.default_item
                default_item.time = null
                default_item.timing_type = 'rounds'
                default_item.rounds = ''
                this.search = search
                this.default_item = default_item
                if (this.$refs && this.$refs.default_rounds_input) {
                    this.$refs.default_rounds_input.focus()
                    this.default_item_rounds_msg = ''
                }
                if (this.$refs && this.$refs.default_time_input) {
                    this.$refs.default_time_input.focus()
                    this.default_item_time_msg = ''
                }
            }
            return false
        }, 
        showAddExercisesCollectionsPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddItem,
                props: {
                    add_item_title: this.add_exercises_collections_title,
                    post_route: baseUrl + 'exercises_collections',
                    entity_type: this.entity_type,
                    field_exercises: true,
                    include_single_choice: this.include_single_choice
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_selected_collection', result)
                }
            })
        },
        getCollections: function ($state) {
            var route = baseUrl + 'exercises_collections'
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + 
            (this.searchItem ? '&search=' + this.searchItem : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.collections.push(...response.data.items)
                        this.default_item.options = this.collections.map(elem => {
                            let array = elem
                            array = {
                                label: elem.name,
                                name: elem.name,
                                training_phase_name: elem.training_phase_name,
                                value: elem.id,
                                exercises: []
                            }
                            return array
                        })
                        if (this.searchItem) {
                            this.search = this.default_item.options[0].label
                            this.selected_collection = this.default_item.options[0]
                        }
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: 50vh;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}

body .search-input {
    width: calc(100% + 30px);
}

.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
    transform-origin: center;
    transform: scale(.5);
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.list-group-item i {
    cursor: pointer;
}

.draggable-item:hover {
    border-style: dotted;
    border-color: rgba(0, 0, 0, .85);
}
</style>
