<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" /><strong>{{ add_item_title }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent class="full-height">
                    <b-list-group-item class="p-0 pt-2">
                        <q-item class="p-0">
                            <q-item-section class="p-0">
                                <q-input dense square color="primary" type="text" id="name" v-model="item.name" :placeholder="translate('name')" />
                                <q-input v-if='descriptionOpen' :placeholder="translate('description')" v-model="item.description" square dense color="primary" type="textarea" rows="2" />
                            </q-item-section>
                            <q-item-section side class="p-0 mt-2" top>
                                <q-btn :color="descriptionOpen ? 'primary' : 'secondary'" class="m-0 mr-3"  @click.stop="toggleVizibility('descriptionOpen')" dense flat round no-caps :icon="descriptionOpen ? 'comment' : 'comment'">
                                    <q-tooltip :offset="[10, 10]">{{ descriptionOpen ? translate('hide_description') : translate('show_description') }}</q-tooltip>
                                </q-btn>
                            </q-item-section>
                        </q-item>
                        <template v-if="field_unit">
                            <!-- Used for Measurements. -->
                            <include-single-select item_ref="units_ref" :placeholder_find_item="'find_unit'" :entity_type="'unit'" :field_name="'unit'" :get_route_to_include="get_units_route" />
                        </template>
                        <template v-if="include_single_choice && include_single_choice.length">
                            <div v-for="(choice, i) in include_single_choice" :key="'list-choice-field-' + i + '-' + choice.id">
                                <include-single-select :item_ref="choice.field_name + '_ref'" :placeholder_find_item="'find_' + choice.field" :entity_type="entity_type" :field_name="choice.field" :get_route_to_include="choice.get_route_to_include" />
                            </div>
                        </template>
                        <template v-if="field_exercises === true">
                            <include-exercises-form-light 
                                :placeholder_find_item="translate('find_exercise')" 
                                :get_route="'exercises'" :entity_type="'exercise'" class="pl-3 pr-3 mt-4 pt-4">
                            </include-exercises-form-light>
                        </template>
                        <template v-if="field_exercises_collections === true">
                            <div class="mt-4"></div>
                            <include-exercises-collections-form-light class="pl-3 pr-3 mt-4 pt-4"
                                :placeholder_find_item="translate('find_exercises_collection')" 
                                :get_route="'exercises_collections'" 
                                :entity_type="'exercises_collection'">
                            </include-exercises-collections-form-light>
                        </template>
                        <template v-if="field_muscle">
                            <!-- Used for Muscles. -->
                            <include-multiple-select item_ref="muscles_ref" :initial_ids="selected_muscles" placeholder_find_item="find_muscle" entity_type="muscles" field_title="muscles" field_name="muscles" :get_route_to_include="get_muscles_route" />
                        </template>
                        <template v-if="field_equipment">
                            <!-- Used for Equipments. -->
                            <!-- <include-multiple-select item_ref="equipments_ref" :initial_ids="selected_equipments" placeholder_find_item="find_equipment" entity_type="equipments" field_title="equipments" field_name="equipments" :get_route_to_include="get_equipments_route" /> -->
                            <include-custom-select item_ref="equipments_ref" :initial_ids="selected_equipments" placeholder_find_item="find_equipment" entity_type="equipments" field_title="equipments" field_name="equipments" :get_route_to_include="get_equipments_route" />
                        </template>
                    </b-list-group-item>
                    <template v-if="field_medical_problem">
                        <b-list-group-item class="pt-2 pb-2 font-weight-bold">
                            {{ translate('medical_problems') }}
                        </b-list-group-item>
                        <b-list-group-item class="p-0" style="margin-top: -1px;">
                            <!-- <include-multiple-select :initial_ids="selected_medical_problems_recommended" placeholder_find_item="find_medical_problem" :entity_type="entity_type + '_recommended'" field_title="recommended_medical_problems" field_name="medical_problems" :get_route_to_include="get_medical_problems_route" />
                            <include-multiple-select :initial_ids="selected_medical_problems_not_recommended" placeholder_find_item="find_medical_problem" :entity_type="entity_type + '_not_recommended'" field_title="not_recommended_medical_problems" field_name="medical_problems" :get_route_to_include="get_medical_problems_route" />
                            <include-multiple-select :initial_ids="selected_medical_problems_forbidden" placeholder_find_item="find_medical_problem" :entity_type="entity_type + '_forbidden'" field_title="forbidden_medical_problems" field_name="medical_problems" :get_route_to_include="get_medical_problems_route" /> -->
                            <div class="pl-3 pr-3">
                                <q-input dense class="search-input mb-2" @input="isTypingMedicalProblem = true" v-model="searchMedicalProblem" id="filternamemedical_problem" type="text" :placeholder="translate('medical_problem')">
                                    <template v-slot:prepend>
                                        <q-icon name="search" />
                                    </template>
                                    <template v-slot:append>
                                        <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddMedicalProblemsPanel" flat dense color="dark" no-caps :label="add_medical_problem_title" icon="add" />
                                    </template> 
                                </q-input>
                                <div class="row m-0">
                                    <div class="col p-0">
                                        <small class="card-header bg-light p-1 display-block text-center mb-1">{{ translate('recommended') }}</small>
                                        <q-item dense class="p-0 min-height-0 p-0" v-for="(medical_problem,i) in selected_medical_problems_recommended" :key="'medical_problem-' + i + '-' + medical_problem.id">
                                            <q-item-section>
                                                <q-checkbox dense :class="$q.screen.lt.sm ? 'small-checkbox-label' : ''" v-model="selected_medical_problems_recommended" color="primary" :val="medical_problem" :label="medical_problem.name" />
                                            </q-item-section>
                                        </q-item>
                                        <div class="list-container-scroll vh-25 list_wrapper-medical_problems">
                                            <q-item dense class="p-0 min-height-0 p-0" v-for="(medical_problem,i) in medical_problems" :key="'medical_problem-' + i + '-' + medical_problem.id">
                                                <q-item-section v-if="displaySelectedMedicalProblemsVModel(medical_problem)">
                                                    <q-checkbox dense :class="$q.screen.lt.sm ? 'small-checkbox-label' : ''" v-model="selected_medical_problems_recommended" color="primary" :val="medical_problem" :label="medical_problem.name" />
                                                </q-item-section>
                                            </q-item>
                                        </div>
                                    </div>
                                    <div class="col p-0">
                                        <small class="card-header bg-light p-1 display-block text-center mb-1">{{ translate('not_recommended') }}</small>
                                        <q-item dense class="p-0 min-height-0 p-0" v-for="(medical_problem,i) in selected_medical_problems_not_recommended" :key="'medical_problem-' + i + '-' + medical_problem.id">
                                            <q-item-section>
                                                <q-checkbox dense :class="$q.screen.lt.sm ? 'small-checkbox-label' : ''" v-model="selected_medical_problems_not_recommended" color="primary" :val="medical_problem" :label="medical_problem.name" />
                                            </q-item-section>
                                        </q-item>

                                        <div class="list-container-scroll vh-25 list_wrapper-medical_problems">
                                            <q-item dense class="min-height-0 p-0" v-for="(medical_problem,i) in medical_problems" :key="'medical_problem-' + i + '-' + medical_problem.id">
                                                <q-item-section v-if="displaySelectedMedicalProblemsVModel(medical_problem)">
                                                    <q-checkbox dense :class="$q.screen.lt.sm ? 'small-checkbox-label' : ''" v-model="selected_medical_problems_not_recommended" color="primary" :val="medical_problem" :label="medical_problem.name" />
                                                </q-item-section>
                                            </q-item>
                                        </div>
                                    </div>
                                    <div class="col p-0">
                                        <small class="card-header bg-light p-1 display-block text-center mb-1">{{ translate('forbidden') }}</small>
                                        <q-item dense class="p-0 min-height-0 p-0" v-for="(medical_problem,i) in selected_medical_problems_forbidden" :key="'medical_problem-' + i + '-' + medical_problem.id">
                                            <q-item-section>
                                                <q-checkbox dense :class="$q.screen.lt.sm ? 'small-checkbox-label' : ''" v-model="selected_medical_problems_forbidden" color="primary" :val="medical_problem" :label="medical_problem.name" />
                                            </q-item-section>
                                        </q-item>
                                        <div class="list-container-scroll vh-25 list_wrapper-medical_problems">
                                            <q-item dense class="min-height-0 p-0" v-for="(medical_problem,i) in medical_problems" :key="'medical_problem-' + i + '-' + medical_problem.id">
                                                <q-item-section v-if="displaySelectedMedicalProblemsVModel(medical_problem)">
                                                    <q-checkbox dense :class="$q.screen.lt.sm ? 'small-checkbox-label' : ''" v-model="selected_medical_problems_forbidden" color="primary" :val="medical_problem" :label="medical_problem.name" />
                                                </q-item-section>
                                            </q-item>
                                        </div>
                                    </div>
                                </div>
                                <infinite-loading :identifier="'infLoading-medical_problems-' + infMedicalProblems" slot="append" @infinite="getMedicalProblems" />
                            </div>
                        </b-list-group-item>
                    </template>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerItem">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../../main'
import IncludeSingleSelect from './IncludeSingleSelect'
import IncludeMultipleSelect from './IncludeMultipleSelect'
import IncludeCustomSelect from './IncludeCustomSelect'
import AddUnits from './AddItem'
import AddMedicalProblems from './AddItem'
import IncludeExercisesLight from './IncludeExercisesLight'
import IncludeExercisesCollectionsLight from './IncludeExercisesCollectionsLight'

export default {
    name: 'AddItem',
    props: ['field_exercises', 'field_exercises_collections', 'entity_type', 'add_item_title', 'post_route', 'field_unit', 'field_muscle', 'field_equipment', 'field_medical_problem', 'field_description', 'include_single_choice', 'field_unit_title', 'field_muscle_title', 'field_equipment_title', 'field_medical_problem_title'],
    components: {
        'include-single-select': IncludeSingleSelect,
        'include-multiple-select': IncludeMultipleSelect,
        'include-custom-select': IncludeCustomSelect,
        'include-exercises-form-light': IncludeExercisesLight,
        'include-exercises-collections-form-light': IncludeExercisesCollectionsLight,
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            descriptionOpen: false, 
            loading: false,

            isTypingUnit: false,
            searchUnit: '',
            units: [],
            units_options: [],
            page_units: 1,
            infUnits: +new Date(),
            isTypingMedicalProblem: false,
            searchMedicalProblem: '',
            medical_problems: [],
            page_medical_problems: 1,
            infMedicalProblems: +new Date(),
            state: {},
            selected_unit: null,
            selected_muscles: [],
            selected_equipments: [],
            selected_medical_problems_forbidden: [],
            selected_medical_problems_recommended: [],
            selected_medical_problems_not_recommended: [],
            item: {
                name: null,
                description: null,
                training_category_id: null,
            },
            selected_training_category_id: null,
            selected_training_category_name: null,
            selected_training_phase_id: null,
            selected_training_phase_name: null,
            selected_training_level_id: null,
            selected_training_level_name: null,
            selected_training_type_id: null,
            selected_training_type_name: null,
            selected_exercises: [],
            selected_exercises_collections: [],
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
        
        if (this.include_single_choice && this.include_single_choice.length) {
            this.include_single_choice.map(choice => {
                eventBus.$on('update_selected_single_choice_id_' + this.entity_type + '_'+ choice.field, (item) => {                    
                    this.updateSelectedTrainingClassificationId(item, choice.field)
                })
            })
        }
        eventBus.$on('update_selected_single_choice_id_unit_unit', (item) => {
            this.updateSelectedUnitId(item)
        })
        if (this.field_muscle) {
            eventBus.$on('update_selected_multiple_choices_muscles_muscles', (items) => {
                this.updateSelectedMusclesIds(items)
            })
        }
        if (this.field_equipment) {
            eventBus.$on('update_selected_multiple_choices_equipments_equipments', (items) => {
                this.updateSelectedEquipmentIds(items)
            })
        }
        eventBus.$on('update_selected_multiple_choices_' + this.entity_type + '_medical_problems_recommended', (items) => {
            this.updateSelectedMedicalProblemsRecommendedIds(items)
        })
        if (this.field_exercises) {
            eventBus.$on('update_selected_exercises', (items) => {                    
                this.updateSelectedExercises(items)
            })
        }
        if (this.field_exercises_collections) {
            eventBus.$on('update_selected_exercises_collections', (items) => {                    
                this.updateSelectedExercisesCollections(items)
            })
        }
    },
    computed: {
        get_muscles_route: function () {
            return baseUrl + 'muscles'
        },
        get_medical_problems_route: function () {
            return baseUrl + 'medical_problems'
        },
        get_equipments_route: function () {
            return baseUrl + 'equipments'
        },
        get_units_route: function () {
            return baseUrl + 'units'
        },
        add_unit_title: function () {
            return this.translate('create_unit')
        },
        post_route_units: function () {
            return baseUrl + 'units'
        },
        entity_type_units: function () {
            return 'units'
        },
        add_muscle_title: function () {
            return this.translate('create_muscle')
        },
        post_route_muscles: function () {
            return baseUrl + 'muscles'
        },
        entity_type_muscles: function () {
            return 'muscles'
        },
        add_equipment_title: function () {
            return this.translate('create_equipment')
        },
        post_route_equipments: function () {
            return baseUrl + 'equipments'
        },
        entity_type_equipments: function () {
            return 'equipments'
        },
        add_medical_problem_title: function () {
            return this.translate('new_medical_problem')
        },
        post_route_medical_problems: function () {
            return baseUrl + 'medical_problems'
        },
        entity_type_medical_problems: function () {
            return 'medical_problems'
        },
        reference_options: function () {
            var options = [{
                    value: 'recommended',
                    label: this.translate('recommended')
                },
                {
                    value: 'not_recommended',
                    label: this.translate('not_recommended')
                },
                {
                    value: 'forbidden',
                    label: this.translate('forbidden')
                }
            ]
            
            return options
        },
    },
    watch: {
        searchUnit: _.debounce(function () {
            this.isTypingUnit = false
        }, 200),
        isTypingUnit: function (value) {
            if (!value) {
                this.resetUnitsList()
            }
        },
        searchMedicalProblem: _.debounce(function () {
            this.isTypingMedicalProblem = false
        }, 200),
        isTypingMedicalProblem: function (value) {
            if (value) {
                this.resetMedicalProblemsList()
            } else {
                this.page_medical_problems = 1
                this.medical_problems = []
            }
        },
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        toggleVizibility: function (field) {
            this[field] = !this[field]
        },
        updateSelectedExercises: function (items) {
            if (items && items.length) {
                this.selected_exercises = items
            }
        },
        updateSelectedExercisesCollections: function (items) {
            if (items && items.length) {
                this.selected_exercises_collections = items
            }
        },
        displaySelectedMedicalProblemsVModel: function (medical_problem) {
            return (!this.selected_medical_problems_recommended.includes(medical_problem) && 
                    !this.selected_medical_problems_not_recommended.includes(medical_problem) && 
                    !this.selected_medical_problems_forbidden.includes(medical_problem))
        },
        classSelectedMedicalProblemsVModel: function (medical_problem) {
            return (this.selected_medical_problems_recommended.includes(medical_problem) || 
                    this.selected_medical_problems_not_recommended.includes(medical_problem) || 
                    this.selected_medical_problems_forbidden.includes(medical_problem)) ? 'hidden' : ''
        },
        showAddUnitsPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddUnits,
                props: {
                    add_item_title: this.add_unit_title,
                    post_route: this.post_route_units,
                    entity_type: this.entity_type_units
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetUnitsList()
                }
            })
        },
        showAddMedicalProblemsPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddMedicalProblems,
                props: {
                    add_item_title: this.add_medical_problem_title,
                    post_route: this.post_route_medical_problems,
                    entity_type: this.entity_type_medical_problems
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetUnitsList()
                }
            })
        },
        resetUnitsList: function () {
            this.page_units = 1
            this.units = []
            this.infUnits++
        },
        getUnits: function ($state) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = baseUrl + 'units?page=' + this.page_units +
                '&items_per_page=10000' +
                (this.searchUnit ? '&search=' + this.searchUnit : '')
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items && response.data.items.length > 0) {
                        this.units_options.push(...response.data.items.map(entity => {
                            let array = entity
                            array = {value: entity.id, label: entity.name}
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page_units++
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        resetMedicalProblemsList: _.debounce(function () {
            this.page_medical_problems = 1
            this.medical_problems = []
            this.infMedicalProblems++
        }, 200),
        getMedicalProblems: function ($state) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = baseUrl + 'medical_problems?page=' + this.page_medical_problems +
                '&items_per_page=' + this.$items_per_page +
                (this.searchMedicalProblem ? '&search=' + this.searchMedicalProblem : '')
            this.state = $state
            var medical_problems = this.medical_problems
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        medical_problems.push(...response.data.items.filter(elem => {
                            return !this.selected_medical_problems_recommended.includes(elem) || !this.selected_medical_problems_not_recommended.includes(elem) || !this.selected_medical_problems_forbidden.includes(elem)
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page_medical_problems++
                        $state.loaded()
                    } else {
                        this.medical_problems = medical_problems
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        updateSelectedTrainingClassificationId: function (item, choice_field) {
            this['selected_' + choice_field + '_id'] = item.id
            this['selected_' + choice_field + '_name'] = item.name
        },
        updateSelectedUnitId: function (item) {
            this.selected_unit = item
        },
        updateSelectedMusclesIds: function (items) {
            this.selected_muscles = items.map(elem => {
                return elem.value ? elem.value : ''
            })
        },
        updateSelectedEquipmentIds: function (items) {
            this.selected_equipments = items.map(elem => {
                return elem.id ? elem.id : ''
            })
        },
        registerItem: function () {
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }

            let name = this.item.name
            var data = {
                'name': name,
            }

            if (this.selected_unit) {
                data['unit_id'] = this.selected_unit.id
            }

            if (this.item.description) {
                data['description'] = this.item.description
            }

            if (this.selected_training_category_id) {
                data['training_category_id'] = parseInt(this.selected_training_category_id)
            }

            if (this.include_single_choice && this.include_single_choice.length) {
                this.include_single_choice.map(choice => {
                    if (this['selected_' + choice.field + '_id']) {
                        data[choice.field +  '_id'] = this['selected_' + choice.field + '_id']
                    }
                })
            }

            // Links.
            data['links'] = {
                muscle: [],
                equipment: [],
                not_recommended_medical_problem: [],
                forbidden_medical_problem: [],
                recommended_medical_problem: [],
            }

            // Links for collections.
            if (this.field_exercises || this.field_exercises_collections) {
                data['links'] = []
            }
            
            if (this.selected_exercises && this.selected_exercises.length) {
                this.selected_exercises.map(exer => {
                    let exercise = {"exercise_id": parseInt(exer.exercise_id), "reps": parseInt(exer.reps), "value_per_rep": parseInt(exer.value_per_rep) ? parseInt(exer.value_per_rep) : 0}
                    data['links'].push(exercise)
                })
            }
            if (this.selected_exercises_collections && this.selected_exercises_collections.length) {
                this.selected_exercises_collections.map(exer => {
                    let exercises_collection = {"exercises_collection_id": parseInt(exer.collection_id), "rounds": parseInt(exer.rounds), "seconds": parseInt(exer.time)}
                    data['links'].push(exercises_collection)
                })
            }

            // Muscles.
            if (this.selected_muscles && this.selected_muscles.length) {
                // Send array with ids.
                this.selected_muscles.map(elem => {
                    let id = elem.value ? elem.value : elem
                    data['links']['muscle'].push(parseInt(id))
                })
            }
            // Equipments.
            if (this.selected_equipments && this.selected_equipments.length) {
                // Send array with ids.
                this.selected_equipments.map(elem => {
                    let id = elem.value ? elem.value : elem
                    data['links']['equipment'].push(parseInt(id))
                })
            }
            // not_recommended_medical_problem.
            if (this.selected_medical_problems_not_recommended && this.selected_medical_problems_not_recommended.length) {
                // Send array with ids.
                this.selected_medical_problems_not_recommended.map(elem => {
                    data['links']['not_recommended_medical_problem'].push(elem.id)
                })
            }
            if (this.selected_medical_problems_forbidden && this.selected_medical_problems_forbidden.length) {
                // Send array with ids.
                this.selected_medical_problems_forbidden.map(elem => {
                    data['links']['forbidden_medical_problem'].push(elem.id)
                })
            }
            if (this.selected_medical_problems_recommended && this.selected_medical_problems_recommended.length) {
                // Send array with ids.
                this.selected_medical_problems_recommended.map(elem => {
                    data['links']['recommended_medical_problem'].push(elem.id)
                })
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.post(this.post_route, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_' + this.entity_type + '_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        eventBus.$emit('update_all')
                        eventBus.$emit('set_selected_new_item', {name: name, field_name: this.entity_type})
                        this.$emit('closePanel', {
                            status: response.data.status,
                            name: name
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })

                })
        },
        closePanel() {
            this.$emit('closePanel', {})
        }
    }
}
</script>
