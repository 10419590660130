<template>
<div>
    <template>
        <q-item class="pl-0 pt-0 pb-0" :class="no_add_button === true ? 'pr-0' : ''">
            <q-item-section class="p-0">
                <q-select 
                    use-chips :label="translate(field_name)" dense color="primary" @filter="filter" use-input :options="items_options" v-model="selected_single_choice" @input="emitUpdates(selected_single_choice.value, selected_single_choice.label, selected_single_choice.worker_id)"
                    class="flex-wrap" 
                    @virtual-scroll="onScroll" @focus="resetItems();getItems()" 
                    :loading="loading" :ref="item_ref" 
                    map-options>
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">{{ translate('no_results') }}</q-item-section>
                        </q-item>
                    </template>
                    <template v-slot:selected-item="scope" v-if="selected_single_choice">
                        <q-chip :class="with_image === true ? 'pl-0' : ''"
                            removable dense @remove="scope.removeAtIndex(scope.index)" 
                            :tabindex="scope.tabindex" text-color="primary">
                            <q-avatar class="shadow-2" v-if="with_image === true">
                                <img v-if="selected_single_choice.image_link" class="black-and-white" :src="selected_single_choice.image_link">
                                <i v-else class="fas fa-user fa-1x text-muted"></i>
                            </q-avatar>
                            {{ scope.opt.label }}
                        </q-chip>
                    </template>
                    <template v-slot:option="scope">
                        <q-item :tabindex="scope.tabindex" :class="scope.selected ? 'q-manual-focusable--focused q-item--active text-primary' : ''" text-color="primary" clickable @click="scope.toggleOption(scope.opt)">
                            <q-item-section avatar v-if="with_image === true">
                                <q-avatar class="shadow-2">
                                    <img v-if="scope.opt.image_link" class="black-and-white" :src="scope.opt.image_link">
                                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                                </q-avatar>
                            </q-item-section>
                            <q-item-section>{{ scope.opt.label }}</q-item-section>
                        </q-item>
                    </template>
                </q-select>
            </q-item-section>
            <template v-if="!no_add_button || no_add_button !== true">
                <q-item-section side class="p-0">
                    <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddToIncludePanel" dense round color="primary" no-caps icon="add">
                        <q-tooltip :offset="[10, 10]">{{ add_button_text }}</q-tooltip>
                    </q-btn>
                </q-item-section>
            </template>
        </q-item>
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"

import AddToInclude from './AddItem'

export default {
    name: 'IncludeSingleSelect',
    props: ['with_image', 'no_add_button', 'get_route_to_include', 'get_route_extra_query', 'tooltip_text', 'item_id', 'entity_type', 'field_name', 'initial_id', 'initial_name', 'item_ref'],
    computed: {
        items_options_strings: {
            get() {
                return this.items_options.map(elem => {
                    return {label: elem.label, value: elem.value}
                })
            },
            set() {
                return this.items_options.map(elem => {
                    return {label: elem.label, value: elem.value}
                })
            }
        },
        add_button_text: function () {
            if (this.tooltip_text) {
                return this.translate(this.tooltip_text)
            }
            return this.translate('create_' + this.get_route_to_include.replace(baseUrl, '').toLowerCase())
        },
        no_selection: function () {
            return [{
                id: null,
                name: '',
                label: this.translate('no_' + this.field_name)
            }]
        }
    },
    data: function () {
        return {
            new_item: null,
            loading: false,
            items_options: [],
            baseUrl: baseUrl,
            items: [],
            page: 1,
            selected_single_choice: parseInt(this.initial_id) !== 0 ? this.initial_id : null,
        }
    },
    mounted: function () {
        eventBus.$on('update_selected_single_choice_' + this.entity_type + '_' + this.field_name, (item) => {
            this.updateSelectedSingleChoiceId(item)
        })
        eventBus.$on('set_selected_new_item', (item) => {
            // Fill in the new item object.
            this.new_item = item

            // Apply event emmitted for this referenced dropdown.
            if (item.field_name === this.field_name) {
                // Empty arrays.
                this.resetItems()
                // Load items.
                this.loadItems(this.$refs[this.item_ref])
                // Dynamically set as selected the new item.
                this.getNewItemInfo(item)
            }
        })
    },
    methods: {
        filter: function (val, update, abort) {
            // update() on searching in the dropdown.
            update(() => {
                let items = this.items
                let options = this.items_options
                if (val) {
                    let needle = val.toLowerCase()
                    options = this.items_options_strings.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                } else {
                    options = items
                }
                this.items_options = options
            }),
            // Use ref to move options around, for the referenced dropdown.
            ref => {
                if (val !== '' && ref.options.length > 0) { 
                    // Reset optionIndex in case there is something selected.
                    this.$refs[this.item_ref].setOptionIndex(-1)
                    // Focus the first selectable option and do not update the input-value.
                    this.$refs[this.item_ref].moveOptionSelection(1, true)
                }
            }
        },
        onScroll: function ({ to, ref }) {
            if (this.loading !== true) {
                this.loading = true
                if (this.loading === true) {
                    // If new item was added, empty arrays.
                    if (this.new_item && this.new_item.length) {
                        this.resetItems()
                    }
                    // Load items.
                    this.loadItems(ref)
                    // Stop the loading.
                    this.loading = false
                }
            }
        }, 
        loadItems: _.debounce(function (ref) {
            if (this.items && this.items.length) {
                if (this.items['next_page'] === true) {
                    let item = null
                    if (this.new_item && this.new_item.length) {
                        item = this.new_item
                    }
                    this.getItems(item)
                }
                this.$nextTick(() => {
                    ref.refresh()
                })
            }
        }, 500),
        showAddToIncludePanel: function () {
            const panelInstance = this.$showPanel({
                component: AddToInclude,
                props: {
                    add_item_title: this.add_button_text,
                    post_route: this.get_route_to_include ? this.get_route_to_include : this.post_route,
                    entity_type: this.field_name ? this.field_name : this.entity_type,
                }
            })
        },
        updateSelectedSingleChoiceId: function (item) {
            this.selected_single_choice = item
        },
        emitUpdates: _.debounce(function (id, name, worker_id) {
            if (this.selected_single_choice && this.selected_single_choice.value && this.selected_single_choice.label) {
                eventBus.$emit('update_selected_single_choice_id_' + this.entity_type + '_' + this.field_name, {
                    id: this.selected_single_choice.value,
                    name: this.selected_single_choice.label,
                    worker_id: worker_id
                })
            }
        }, 200),
        resetItems: function () {
            this.page = 1
            this.items = []
            this.items_options = []
            this.items_options_strings = []
        },
        getNewItemInfo: function (item) {
            var route = this.get_route_to_include
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&status=active' + 
                (item.name ? '&search=' + item.name : '') + 
            (this.get_route_extra_query ? this.get_route_extra_query : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            let items_options = this.items_options
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items && response.data.items.length > 0) {
                        this.selected_single_choice = response.data.items.map(entity => {
                            if (entity.name === item.name) {
                                let array = entity
                                array = {value: entity.id, label: entity.name, worker_id: entity.worker_id ? entity.worker_id : null, image_link: entity.image_link ? baseUrl + entity.image_link : null}
                                this.emitUpdates({id: entity.id, name: entity.name, worker_id: entity.worker_id ? entity.worker_id : null})
                                return array
                            }
                        })[0]
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getItems: function (item) {
            var route = this.get_route_to_include
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&status=active' + 
            (this.get_route_extra_query ? this.get_route_extra_query : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            let items_options = this.items_options
            let page = this.page
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items && response.data.items.length > 0) {
                        items_options.push(...response.data.items.map(entity => {
                            let array = entity
                            array = {value: entity.id, label: entity.name, worker_id: entity.worker_id ? entity.worker_id : null, image_link: entity.image_link ? baseUrl + entity.image_link : null}
                            if (item && entity.name === item.name) {
                                this.selected_single_choice = array
                            }
                            return array
                        }))
                    }
                    this.items_options = items_options
                    this.items = items_options
                    this.items['next_page'] = response.data.next_page
                    this.items['total_pages'] = response.data.total_pages
                    if (response.data.next_page === true) {
                        page++
                        this.page = page
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: calc(50px * 3);
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    // border-color: rgba(0, 0, 0, .16);
    border-color: transparent;
    margin: .5rem 0 0;
    min-height: 35px;
    transition: border-color .1s ease-in-out;

    &:hover {
        // border-color: rgba(0, 0, 0, .26);
        border-color: transparent;
    }

    .q-radio {
        transform: scale(0.85);
    }
}

body .search-input {
    width: calc(100% + 30px);
}
</style>
