<template>
<div>
    <template>
        <q-item class="pl-0 pt-0 pb-0">
            <q-item-section class="p-0">
                <q-select 
                    use-input 
                    multiple dense use-chips color="primary" class="flex-wrap" 
                    @virtual-scroll="onScroll" @focus="resetItems();getItems()" 
                    @filter="filter" @input="emitUpdates"
                    :loading="loading" :label="translate(field_title)" :ref="item_ref" 
                    :options="items_options" map-options v-model="selected_multiple_choices">
                    <template v-slot:no-option>
                        <q-item>
                            <q-item-section class="text-grey">{{ translate('no_results') }}</q-item-section>
                        </q-item>
                    </template>
                    <template v-if="selected_multiple_choices && selected_multiple_choices.length" v-slot:append>
                        <q-icon name="close" @click.stop.prevent="emitUpdates([])" class="cursor-pointer" />
                    </template>
                    <template v-slot:selected-item="scope" v-if="selected_multiple_choices && selected_multiple_choices.length">
                        <q-chip
                            removable dense @remove="scope.removeAtIndex(scope.index)" 
                            :tabindex="scope.tabindex" text-color="primary">
                            {{ scope.opt.label }}
                        </q-chip>
                    </template>
                </q-select>
            </q-item-section>
            <q-item-section side class="p-0">
                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddToIncludePanel" dense round color="primary" no-caps icon="add">
                    <q-tooltip :offset="[10, 10]">{{ add_button_text }}</q-tooltip>
                </q-btn>
            </q-item-section>
        </q-item>
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"
import AddToInclude from './AddItem'

export default {
    name: 'IncludeMultipleSelect',
    props: ['get_route_to_include', 'item_id', 'entity_type', 'field_name', 'initial_items', 'initial_name', 'item_ref', 'field_title'],
    computed: {
        items_options_strings: {
            get() {
                return this.items_options.map(elem => {
                    return {label: elem.label, value: elem.value}
                })
            },
            set() {
                return this.items_options.map(elem => {
                    return {label: elem.label, value: elem.value}
                })
            }
        },
        add_button_text: function () {
            return this.translate('create_' + this.get_route_to_include.replace(baseUrl, '').toLowerCase())
        },
        no_selection: function () {
            return [{
                id: null,
                name: '',
                label: this.translate('no_' + this.field_name)
            }]
        }
    },
    data: function () {
        return {
            new_item: null,
            loading: false,
            items_options: [],
            items: [],
            page: 1,
            selected_multiple_choices: this.initial_items && this.initial_items.length ? this.initial_items : [],
            eventBus: eventBus
        }
    },
    mounted: function () {
        eventBus.$on('update_selected_multiple_choices_' + this.entity_type + '_' + this.field_name, (items) => {
            this.updateSelectedMultipleChoicesIds(items)
        })
        eventBus.$on('set_selected_new_item', (item) => {
            // Fill in the new item object.
            this.new_item = item

            // Apply event emmitted for this referenced dropdown.
            if (item.field_name === this.field_name) {
                // Empty arrays.
                this.resetItems()
                // Load items.
                this.loadItems(this.$refs[this.item_ref])
                // Dynamically set as selected the new item.
                this.getNewItemInfo(item)
            }
        })
    },
    methods: {
        filter: function (val, update, abort) {
            // update() on searching in the dropdown.
            update(() => {
                let items = this.items
                let options = this.items_options
                if (val) {
                    let needle = val.toLowerCase()
                    options = this.items_options_strings.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                } else {
                    options = items
                }
                this.items_options = options
            }),
            // Use ref to move options around, for the referenced dropdown.
            ref => {
                if (val !== '' && ref.options.length > 0) { 
                    // Reset optionIndex in case there is something selected.
                    this.$refs[this.item_ref].setOptionIndex(-1)
                    // Focus the first selectable option and do not update the input-value.
                    this.$refs[this.item_ref].moveOptionSelection(1, true)
                }
            }
        },
        onScroll: function ({ to, ref }) {
            if (this.loading !== true) {
                this.loading = true
                if (this.loading === true) {
                    // If new item was added, empty arrays.
                    if (this.new_item && this.new_item.length) {
                        this.resetItems()
                    }
                    // Load items.
                    this.loadItems(ref)
                    // Stop the loading.
                    this.loading = false
                }
            }
        }, 
        loadItems: _.debounce(function (ref) {
            if (this.items && this.items.length) {
                if (this.items['next_page'] === true) {
                    let item = null
                    if (this.new_item && this.new_item.length) {
                        item = this.new_item
                    }
                    this.getItems(item)
                }
                this.$nextTick(() => {
                    ref.refresh()
                })
            }
        }, 500),
        resetItems: function () {
            this.page = 1
            this.items = []
            this.items_options = []
            this.items_options_strings = []
        },
        showAddToIncludePanel: function () {
            const panelInstance = this.$showPanel({
                component: AddToInclude,
                props: {
                    add_item_title: this.add_button_text,
                    post_route: this.get_route_to_include ? this.get_route_to_include : this.post_route,
                    entity_type: this.entity_type,
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_all')
                }
            })
        },
        updateSelectedMultipleChoicesIds: function (items) {
            this.selected_multiple_choices = items
        },
        emitUpdates: function (items) {
            if (items) {
                this.selected_multiple_choices = items
            }
            eventBus.$emit('update_selected_multiple_choices_' + this.entity_type + '_' + this.field_name, this.selected_multiple_choices)
        }, 
        emitUpdates: _.debounce(function (items) {
            if (items) {
                this.selected_multiple_choices = items
            }
            eventBus.$emit('update_selected_multiple_choices_' + this.entity_type + '_' + this.field_name, this.selected_multiple_choices)
        }, 200),
        getNewItemInfo: function (item) {
            var route = this.get_route_to_include
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&status=active' +
                (item.name ? '&search=' + item.name : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            let items_options = this.items_options
            let selected_multiple_choices = this.selected_multiple_choices
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items && response.data.items.length > 0) {
                        selected_multiple_choices.push(...response.data.items.map(entity => {
                            if (entity.name === item.name && selected_multiple_choices.filter((elem) => elem.label === entity.name).length === 0) {
                                let array = entity
                                array = {value: entity.id, label: entity.name}
                                return array
                            }
                        }))
                        if (selected_multiple_choices) {
                            this.emitUpdates(selected_multiple_choices)
                        }
                        this.selected_multiple_choices = selected_multiple_choices
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getItems: function (item) {
            var route = this.get_route_to_include
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&status=active'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            let items_options = this.items_options
            let page = this.page
            let selected_multiple_choices = this.selected_multiple_choices
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items && response.data.items.length > 0) {
                        items_options.push(...response.data.items.map(entity => {
                            let array = entity
                            array = {value: entity.id, label: entity.name}
                            if (item && entity.name === item.name && !selected_multiple_choices.includes(array)) {
                                selected_multiple_choices.push(array)
                            }
                            this.selected_multiple_choices = selected_multiple_choices
                            return array
                        }))
                    }
                    this.items_options = items_options
                    this.items = items_options
                    this.items['next_page'] = response.data.next_page
                    this.items['total_pages'] = response.data.total_pages
                    if (response.data.next_page === true) {
                        page++
                        this.page = page
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: calc(50px * 3);
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    // border-color: rgba(0, 0, 0, .16);
    border-color: transparent;
    margin: .5rem 0 0;
    min-height: 35px;
    transition: border-color .1s ease-in-out;

    &:hover {
        // border-color: rgba(0, 0, 0, .26);
        border-color: transparent;
    }

    .q-radio {
        transform: scale(0.85);
    }
}

body .search-input {
    width: calc(100% + 30px);
}
</style>
