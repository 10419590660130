<template>
<div>
    <template>
        <q-item class="pl-0 pt-0 pb-0">
            <q-item-section class="p-0">
                <div class="position-relative">
                    <q-field class="d-flex flex-wrap mb-0 p-0" :style="!selected_items || selected_items.length===0 ? 'margin-top: -14px;' : ''" dense color="dark" square borderless :label="!selected_items || selected_items.length===0 ? '' : translate(field_title)">
                        <q-input :label="!selected_items || selected_items.length===0 ? translate(field_title) : ''" :ref="item_ref" dense class="p-0 search-input mb-2" color="primary" @click="toggleShowItemsList" @input="isTypingItem = true" v-model="filter" id="filter_available_items" type="text">
                            <template v-slot:prepend v-if="selected_items && selected_items.length">
                                <!-- <q-icon name="search" /> -->
                                <q-chip v-for="(item,i) in selected_items" :key="'selected-item' + i + '-' + item.id" class="pl-0 mb-0" color="light" text-color="primary" :label="item.name" removable dense @remove="selected_multiple_choices.splice(i, 1);selected_items.splice(i, 1)"  />
                            </template>
                            <template v-slot:append>
                                <q-btn v-if="selected_items && selected_items.length" dense flat round icon="close" @click.stop="emptySelectedItems" />
                                <q-icon @click="$refs[item_ref].$el.click()" :name="showItems ? 'fas fa-chevron-up' : 'fas fa-chevron-down'" size="1rem" />
                            </template>
                        </q-input>
                    </q-field>
                    <div v-if="showItems" ref="dropdownMenu" infinite-wrapper class="dropdown__menu items-select-container q-card q-card--square no-border-radius" :style="'overflow: auto; max-height: ' + elementHeight + 'px'">
                        <div v-for="(item,i) in items" :key="'available-item-' + i + '-' + item.id" :id="item.id" class="m-0 available-item">
                            <q-item hoverable clickable v-ripple tag="label" class="m-0" 
                            :class="selected_items.filter((elem) => elem.id === item.id).length ? 'q-item--active text-primary' : ''">
                                <q-item-section>
                                    <q-checkbox class="hidden" :val="item.id" @input="setSelectedItems(item)" v-model="selected_multiple_choices" />
                                    {{ item.name }}
                                </q-item-section>
                            </q-item>
                        </div>
                        <infinite-loading :identifier="'inf-available-items-' + infItems" slot="append" @infinite="getItems" force-use-infinite-wrapper />
                    </div>
                </div>
            </q-item-section>
            <q-item-section side class="p-0">
                <q-btn :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddToIncludePanel" dense round color="primary" no-caps icon="add">
                    <q-tooltip :offset="[10, 10]">{{ add_button_text }}</q-tooltip>
                </q-btn>
            </q-item-section>
        </q-item>
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"
import AddToInclude from './AddItem'

export default {
    name: 'IncludeCustomSelect',
    props: ['get_route_to_include', 'item_id', 'entity_type', 'field_name', 'initial_items', 'initial_name', 'item_ref', 'field_title'],
    computed: {
        add_button_text: function () {
            return this.translate('create_' + this.get_route_to_include.replace(baseUrl, '').toLowerCase())
        },
    },
    data: function () {
        return {
            elementHeight: 0,
            showItems: false,
            new_item: null,
            loading: false,
            page: 1,
            items: [],
            selected_items: [],
            infItems: +new Date(),
            isTypingItem: false,
            state: {},
            filter: '',
            selected_multiple_choices: this.initial_items && this.initial_items.length ? this.initial_items : [],
            eventBus: eventBus
        }
    },
    watch: {
        filter: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()
                this.showItemsList()
            }
        },
    },
    beforeDestroy: function () {
        if (document.querySelector('.tabs')) {
            document.querySelector('.tabs').removeEventListener('click',this.closeList)
        }
    },
    mounted: function () {
        if (document.querySelector('.tabs')) {
            document.querySelector('.tabs').addEventListener('click', this.closeList)
        }

        eventBus.$on('set_selected_new_item', (item) => {
            // Fill in the new item object.
            this.new_item = item

            // Apply event emmitted for this referenced dropdown.
            if (item.field_name === this.field_name) {
                // Empty arrays.
                this.resetMainList()
                // Dynamically set as selected the new item.
                this.getNewItemInfo(item)
            }
        })
    },
    methods: {
        closeList: function (e) {
            let el = this.$refs.dropdownMenu
            let theInput = this.$refs[this.item_ref].$el
            if (el) {
                if (el !== e.target && !el.contains(e.target) && !theInput.contains(e.target)) {
                    this.showItems = false
                }
            }
        },
        emptySelectedItems: function () {
            this.selected_multiple_choices = []
            this.selected_items = []
            this.emitUpdates({})
        },
        setSelectedItems: function (item) {
            if (this.selected_multiple_choices.includes(item.id)) {
                this.selected_items.push(item)
            } else {
                let objIndex = this.selected_items.findIndex(elem => elem.id === item.id)
                this.selected_items.splice(objIndex, 1)
            }
            this.emitUpdates(this.selected_items)
        },
        setElementsHeight: _.debounce(function () {
            if (this.showItems) {
                this.elementHeight = document.querySelector('.available-item').clientHeight * 5
            }
        }, 300),
        toggleShowItemsList: function () {
            if (this.showItems === true) {
                this.hideItemsList()
            } else {
                this.showItemsList()
            }
        },
        hideItemsList: function () {
            this.showItems = false
            this.items = []
            this.page = 1
        },
        showItemsList: function () {
            this.showItems = true
            this.resetMainList()
            this.setElementsHeight()
        },
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        showAddToIncludePanel: function () {
            const panelInstance = this.$showPanel({
                component: AddToInclude,
                props: {
                    add_item_title: this.add_button_text,
                    post_route: this.get_route_to_include ? this.get_route_to_include : this.post_route,
                    entity_type: this.entity_type,
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_all')
                }
            })
        },
        updateSelectedMultipleChoicesIds: function (items) {
            this.selected_multiple_choices = items
            this.selected_items = items
        },
        emitUpdates: _.debounce(function (items) {
            eventBus.$emit('update_selected_multiple_choices_' + this.entity_type + '_' + this.field_name, items)
        }, 200),
        getNewItemInfo: function (item) {
            var route = this.get_route_to_include
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&status=active' +
                (item.name ? '&search=' + item.name : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            let selected_items = this.selected_items
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    if (response.data.items && response.data.items.length > 0) {
                        selected_items.push(...response.data.items.map(entity => {
                            if (entity.name === item.name && selected_items.filter((elem) => elem.name === entity.name).length === 0) {
                                let array = entity
                                array = {id: entity.id, name: entity.name}
                                return array
                            }
                        }))
                        if (selected_items) {
                            this.emitUpdates(selected_items)
                        }
                        this.selected_items = selected_items
                        this.selected_multiple_choices = selected_items.map(elem => { return elem.id })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getItems: function ($state) {
            var route = this.get_route_to_include
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&status=active' + 
                (this.filter ? '&search=' + this.filter : '')
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            let items = this.items
            let page = this.page
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        items.push(...response.data.items)
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        this.items = items
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: calc(50px * 3);
    overflow-y: auto;
}
.items-select-container {
    width: calc(100% - 1.5rem);
    // max-height: 25vh;
    overflow-y: auto;
    background: #fff;
    margin: 0 0.75rem;
    position: absolute;
    left: 0;
    z-index: 2;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    // border-color: rgba(0, 0, 0, .16);
    border-color: transparent;
    margin: .5rem 0 0;
    min-height: 35px;
    transition: border-color .1s ease-in-out;

    &:hover {
        // border-color: rgba(0, 0, 0, .26);
        border-color: transparent;
    }

    .q-radio {
        transform: scale(0.85);
    }
}

body .search-input {
    width: calc(100% + 30px);
}
</style>
